<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-row>
            <b-col
              sm="12"
              md="3"
              lg="3"
              xl="3"
            >
              <b-form-group label-for="startYear">
                <template v-slot:label>
                  Từ năm <span class="text-danger">*</span>
                </template>
                <datepicker
                  id="year"
                  v-model="filter.startYear"
                  name="year"
                  :format="yearFormat"
                  minimum-view="year"
                  input-class="year-select"
                  clear-button-icon="fa fa-times"
                  placeholder="Chọn năm"
                  :bootstrap-styling="true"
                  :clear-button="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="3"
              lg="3"
              xl="3"
            >
              <b-form-group label-for="startYear">
                <template v-slot:label>
                  Đến năm <span class="text-danger">*</span>
                </template>
                <datepicker
                  id="year"
                  v-model="filter.endYear"
                  name="year"
                  :format="yearFormat"
                  minimum-view="year"
                  input-class="year-select"
                  clear-button-icon="fa fa-times"
                  placeholder="Chọn năm"
                  :bootstrap-styling="true"
                  :clear-button="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
              xl="6"
            >
              <div class="text-center mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  @click="getDataSourcesFromStore"
                >
                  <span class="text-nowrap text-right">
                    <feather-icon icon="SearchIcon" /> Tìm kiếm
                  </span>
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  @click="exportExcel"
                >
                  <span class="text-nowrap text-right">
                    <feather-icon icon="DownloadIcon" /> Xuất excel
                  </span>
                </b-button>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col cols="12">
              <div class="d-flex align-items-center mb-1 mt-0">
                <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage - 1) + 1 }}
                  đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                </span>
              </div>
              <vue-good-table
                mode="remote"
                :columns="columns"
                :rows="dataSources"
                :pagination-options="paginationOptions"
                :total-rows="totalRows"
                :line-numbers="true"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <!-- Column: Common -->
                  <span>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage - 1) + 1 }}
                        đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                      </span>
                    </div>
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị </span>
                      <b-form-select
                        v-model="filter.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="totalRows"
                        :per-page="filter.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </b-container>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormGroup, BFormSelect,
  BOverlay, BPagination,
  BRow,
} from 'bootstrap-vue'
import Datepicker from 'vuejs-datepicker'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as XLSX from 'xlsx'
import moment from 'moment/moment'
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'QrEmployeeStudent',
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    BFormGroup,
    BOverlay,
    BRow,
    BButton,
    BCard,
    BContainer,
    BCol,
    Datepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        startYear: null,
        endYear: null,
        currentPage: 1,
        itemsPerPage: 10,
      },
      yearFormat: 'yyyy',
      columns: [
        {
          label: 'Mã trường',
          field: 'organizationCode',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tên trường',
          field: 'organizationName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Mã phòng ban',
          field: 'departmentCode',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tên phòng ban',
          field: 'departmentName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Số lượng sinh viên',
          field: 'studentNum',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Số lượng cán bộ',
          field: 'employeeNum',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Số lượng giảng viên',
          field: 'teacherNum',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
      ],
      header: {
        organizationCode: 'Mã trường',
        organizationName: 'Tên trường',
        departmentCode: 'Mã phòng ban',
        departmentName: 'Tên phòng ban',
        studentNum: 'Số lượng sinh viên',
        employeeNum: 'Số lượng cán bộ',
        teacherNum: 'Số lượng giảng viên',
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'qrEmployeeStudent/qrEmployeeStudents',
      totalRows: 'qrEmployeeStudent/totalRows',
    }),
    fullStartYear() {
      return +moment(this.filter.startYear).format('YYYY')
    },
    fullEndYear() {
      return +moment(this.filter.endYear).format('YYYY')
    },
  },
  methods: {
    ...mapActions({
      getQrEmployeeStudents: 'qrEmployeeStudent/getQrEmployeeStudents',
    }),
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async getDataSourcesFromStore() {
      if (this.isValid()) {
        this.isLoading = true
        try {
          await this.getQrEmployeeStudents({
            startYear: this.fullStartYear,
            endYear: this.fullEndYear,
            currentPage: this.filter.currentPage,
            itemsPerPage: this.filter.itemsPerPage,
          })
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    isValid() {
      if (!this.filter.startYear) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Từ năm là bắt buộc',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return false
      }
      if (!this.filter.endYear) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Đến năm là bắt buộc',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return false
      }
      if (this.fullStartYear > this.fullEndYear) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Từ năm phải nhỏ hơn hoặc bằng đến năm',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return false
      }
      return true
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    exportExcel() {
      if (this.dataSources.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất báo cáo',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      const data = []
      const header = {}
      this.columns.forEach(column => {
        header[column.field] = column.label
      })
      const headerKeys = Object.keys(header)
      const dataSourceKeys = Object.keys(this.dataSources[0])
      const duplicates = dataSourceKeys.filter(val => headerKeys.includes(val))
      duplicates.sort((a, b) => headerKeys.indexOf(a) - headerKeys.indexOf(b))
      this.dataSources.forEach(dataSource => {
        const elm = {}
        duplicates.forEach(key => {
          elm[this.header[key]] = dataSource[key]
        })
        data.push(elm)
      })
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'SinhVien')
      XLSX.writeFile(wb, 'SanLuongCanBoSinhVien.xlsx')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
